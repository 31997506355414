import { IconButton } from '@chakra-ui/react';
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../../styles/theme';

const markerButtonStyle = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  cursor: 'auto !important',
  opacity: '1 !important',
} as const;

interface Props {
  lat: number;
  lng: number;
}

export default function MapMarker(props: Props) {
  return (
    <IconButton
      sx={markerButtonStyle}
      variant="unstyled"
      colorScheme="pink"
      aria-label={'map marker'}
      // TODO: add logic to click icons and select class on left
      // currently not possible as locations are duplicated per class
      disabled
      icon={<FontAwesomeIcon icon={faLocationDot} size="2x" color={theme.colors['pink']['500']} />}
    />
  );
}
