import { List, ListItem } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { resultListStyle } from '../../styles/global';
import { EventResponse } from '../../types/events';
import EventListItem from './EventListItem';

const classResultListItemStyle = {
  padding: 2,
  marginLeft: 0,
  marginBottom: 0,
  cursor: 'pointer',
} as const;

export interface Props {
  events: EventResponse[];
  setMapCenter: Dispatch<SetStateAction<{ lat: number; lng: number } | null>>;
  setMapZoom: Dispatch<SetStateAction<number | null>>;
}

export default function EventsList(props: Props) {
  const { events, setMapCenter, setMapZoom } = props;

  function setMapToEventPlace(event: EventResponse): void {
    setMapCenter({
      lat: event.location.geometry.location.lat,
      lng: event.location.geometry.location.lng,
    });
    setMapZoom(15);
  }

  return (
    <>
      <List sx={{ ...resultListStyle, padding: 0, paddingBottom: '1rem', maxH: '35rem' }}>
        {events.map((event, index) => (
          <ListItem
            sx={classResultListItemStyle}
            key={`class_${index}`}
            data-test-id="class-list-item"
            onClick={() => setMapToEventPlace(event)}
          >
            <EventListItem event={event} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
