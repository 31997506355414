import { Box, Button, Flex, List, ListItem, Text } from '@chakra-ui/react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { AUTH_MODAL_PAGES, ENVIRONMENTS } from '../../constants/enums';
import {
  AUTH_MODAL_OPENED,
  AUTH_MODAL_OPENED_ORIGIN_CLASS,
  AUTH_MODAL_SIGN_UP_VIEWED,
  BOOK_CLASS_BOOKED,
  BOOK_CLASS_OPEN_MODAL,
} from '../../constants/events';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { openAuthModal } from '../../store/modalsSlice';
import { resultListItemStyle, resultListStyle } from '../../styles/global';
import { BossClass } from '../../types/classes';
import { formatDateTime } from '../../utils/dateTime';
import { convertKmToMiles } from '../../utils/distance';
import { loadEventbriteClassButtonWidget } from '../../utils/eventbrite';
import analyticsEvent from '../../utils/logEvent';
import { serializeBossClassForEvents } from '../../utils/serialize/classes';

const classResultListItemStyle = {
  ...resultListItemStyle,
  paddingLeft: 0,
  marginLeft: 0,
  cursor: 'pointer',
} as const;

const bookButtonStyle = {
  width: { base: 10, md: 24 },
  marginTop: { base: 0, md: '0.875rem' },

  '&:last-child': {
    marginBottom: '-2rem !important',
  },

  '> svg': {
    display: { base: 'block', md: 'none' },
  },
} as const;

export interface Props {
  classes: BossClass[];
  setMapCenter: Dispatch<SetStateAction<{ lat: number; lng: number } | null>>;
  setMapZoom: Dispatch<SetStateAction<number | null>>;
}

export default function ClassesList(props: Props) {
  const { classes, setMapCenter, setMapZoom } = props;
  const dispatch: any = useAppDispatch();

  const { user } = useTypedSelector((state) => state);
  const t = useTranslations('QueenHub');

  useEffect(() => {
    if (classes[0] && user.id) {
      // Load eventbrite widget on each class button
      classes.map((bossClass, index) => {
        loadEventbriteClassButtonWidget(bossClass, bookingCompleted);
      });
    }
  }, [classes, user.id]);

  function bookingCompleted(orderId: string, bossClass: BossClass) {
    analyticsEvent(BOOK_CLASS_BOOKED, {
      ...serializeBossClassForEvents(bossClass),
    });
  }

  function onClickOpenBookingWidget(bossClass: BossClass): void {
    analyticsEvent(BOOK_CLASS_OPEN_MODAL, {
      ...serializeBossClassForEvents(bossClass),
    });
  }

  function onClickOpenAuthModal(bossClass: BossClass): void {
    analyticsEvent(AUTH_MODAL_OPENED, { origin_component: 'book_class' });
    analyticsEvent(AUTH_MODAL_SIGN_UP_VIEWED, { origin: 'book_class' });
    analyticsEvent(AUTH_MODAL_OPENED_ORIGIN_CLASS, { ...serializeBossClassForEvents(bossClass) });
    dispatch(openAuthModal(AUTH_MODAL_PAGES.SIGN_UP));
  }

  function setMapToClassPlace(bossClass: BossClass): void {
    setMapCenter({
      lat: parseFloat(bossClass.address.latitude),
      lng: parseFloat(bossClass.address.longitude),
    });
    setMapZoom(15);
  }

  return (
    <>
      {/* Noscript content for SEO - load a script for every class */}
      {user.id &&
        process.env.NEXT_PUBLIC_ENV === ENVIRONMENTS.PRODUCTION &&
        classes.map((bossClass, index) => (
          <noscript key={`eventbrite_event_script_${index}`}>
            <a href={bossClass.eventHref} rel="noopener noreferrer" target="_blank">
              {t('findClass.eventbriteScript')}
            </a>
          </noscript>
        ))}

      <List sx={{ ...resultListStyle, paddingLeft: 0, paddingTop: 6 }}>
        {classes.map((bossClass, index) => (
          <ListItem
            sx={classResultListItemStyle}
            key={`class_${index}`}
            data-test-id="class-list-item"
            onClick={() => setMapToClassPlace(bossClass)}
          >
            <Flex gap={4}>
              <Text flex={1} mb={1} fontWeight={'700'} data-test-id="class-boss-name">
                {bossClass.bossName}
              </Text>

              {user.id ? (
                <Button
                  id={`eventbrite-widget-modal-trigger-${bossClass.eventId}`}
                  sx={bookButtonStyle}
                  onClick={() => onClickOpenBookingWidget(bossClass)}
                  data-test-id="class-book-button"
                >
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  <Box sx={{ display: { base: 'none', md: 'block' } }} as="span">
                    {t('findClass.classList.bookButton')}
                  </Box>
                </Button>
              ) : (
                <Button
                  sx={bookButtonStyle}
                  onClick={() => onClickOpenAuthModal(bossClass)}
                  data-test-id="class-sign-up-button"
                >
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  <Box sx={{ display: { base: 'none', md: 'block' } }} as="span">
                    {t('findClass.classList.signUpButton')}
                  </Box>
                </Button>
              )}
            </Flex>
            <Text mb={0} color="pink.500" fontSize="sm">
              {bossClass.address.address1}, {bossClass.address.city}, {bossClass.address.postalCode}{' '}
              • {convertKmToMiles(bossClass.distance)}mi
            </Text>
            <Text mb={1}>{formatDateTime(bossClass.start)}</Text>
          </ListItem>
        ))}
      </List>
    </>
  );
}
