import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';
import { SPOTLIGHT } from '../../constants/enums';
import { BACKSTAGE_URL } from '../../constants/variables';
import Spotlight from '../ui/Spotlight';

const containerStyle = {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  padding: 6,
  background: 'blackAlpha.50',
} as const;

const backgroundStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  background: 'gray.50',
  zIndex: -2,
} as const;

export default function BecomeABossCTA() {
  const t = useTranslations('Global.becomeABossCTA');
  const bookCallLink = process.env.NEXT_PUBLIC_BOOK_CALL_LINK || '';

  return (
    <Box sx={containerStyle} data-test-id="become-a-boss-cta">
      <Box sx={backgroundStyle} />
      <Spotlight
        option={SPOTLIGHT.LEFT_1}
        style={{
          width: '150%',
          left: '15%',
          bottom: 0,
          top: '-30%',
        }}
      />
      <Box position="relative">
        <Heading as="h2" size="h-xs" mb={1} color="pink.500">
          {t('heading')}
        </Heading>
        <Text size="h-md" maxW={550} mb={1}>
          {t('headline')}
        </Text>
        <Text mb={3}>{t('description')}</Text>

        <Stack direction={{ base: 'column', md: 'row' }}>
          <NextLink href={`${BACKSTAGE_URL}/boss-hub`} passHref>
            <Button variant="outline" mt={1} size="sm">
              {t('findOutMoreButton')}
            </Button>
          </NextLink>
          <NextLink href={`${bookCallLink}`} passHref>
            <Button mt={1} size="sm">
              {t('discoveryCallButton')}
            </Button>
          </NextLink>
        </Stack>
      </Box>
    </Box>
  );
}
