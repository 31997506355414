import { camelKeys } from 'js-convert-case';
import {
  AttendeeEventbriteData,
  BossClass,
  BossClassAttendeeData,
  BossClassEventData,
} from '../../types/classes';

export function serializeBossClass(event: any, distance: number): BossClass {
  return {
    bossName: event.organizer.name,
    start: event.start.utc,
    end: event.end.utc,
    address: camelKeys(event.venue.address, { recursive: true, recursiveInArray: true }),
    distance: distance,
    eventId: event.id,
    eventHref: event.url,
  };
}

export function serializeBossClassForEvents(bossClass: BossClass): BossClassEventData {
  return {
    boss_name: bossClass.bossName,
    start: bossClass.start,
    end: bossClass.end,
    distance: bossClass.distance,
    event_href: bossClass.eventHref,
    event_id: bossClass.eventId,
    venue_address_1: bossClass.address.address1,
    venue_address_2: bossClass.address.address2,
    venue_city: bossClass.address.city,
    venue_region: bossClass.address.region,
    venue_postal_code: bossClass.address.postalCode,
    venue_country: bossClass.address.country,
  };
}

export function serializeBossClassAttendeeData(
  bossClass: any,
  attendees: AttendeeEventbriteData[],
): BossClassAttendeeData {
  return {
    bossClassName: bossClass.name.text,
    attendeeList: attendees.map((attendee: AttendeeEventbriteData) => ({
      firstName: attendee.profile.first_name,
      lastName: attendee.profile.last_name,
      email: attendee.profile.email,
    })),
  };
}
