import {
  Badge,
  Button,
  Flex,
  IconButton,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useTranslations } from 'next-intl';
import { EVENT_BOOK_PAGES, EVENT_TYPE, LICENSE_TYPE } from '../../constants/enums';
import { useAppDispatch } from '../../hooks/store';
import { setEvent } from '../../store/eventSlice';
import { openEventBookModal } from '../../store/modalsSlice';
import { EventResponse } from '../../types/events';

interface Props {
  event: EventResponse;
}

export default function EventListItem(props: Props) {
  const { event } = props;

  const t = useTranslations('QueenHub');
  const dispatch: any = useAppDispatch();

  const startDateTime = new Date(event.startDateTime);
  const startDate = moment(startDateTime).format('dddd Do');
  const startTime = moment(startDateTime).format('h:mmA');

  function onClickViewDetails() {
    dispatch(setEvent(event));
    dispatch(openEventBookModal(EVENT_BOOK_PAGES.DETAILS));
  }

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Button
        variant="unstyled"
        w="full"
        h="fit-content"
        textAlign="start"
        border="1px"
        borderColor="gray.200"
        padding="0.625rem"
        borderRadius="0.75rem"
        textTransform="unset"
        onClick={onClickViewDetails}
        _hover={{ boxShadow: '0px 0px 18px 0px #00000040' }}
        data-test-id="event-list-item-button-card"
        bgColor="white"
      >
        <Flex direction="row" p="0.75rem">
          <Flex direction="column">
            <Flex gap="0.313rem">
              <Badge
                bgColor={event.licenseType === LICENSE_TYPE.HEELS ? 'black' : 'pink.50'}
                textColor={event.licenseType === LICENSE_TYPE.HEELS ? 'pink.100' : 'pink.500'}
                w="fit-content"
                mb="0.313rem"
                data-test-id="license-tag"
              >
                {event.licenseType}
              </Badge>
              {event.type === EVENT_TYPE.WORKSHOP && (
                <Badge
                  bgColor="transparent"
                  textColor="pink.500"
                  w="fit-content"
                  mb="0.313rem"
                  data-test-id="workshop-tag"
                >
                  {event.type}
                </Badge>
              )}
            </Flex>
            <Text
              fontWeight={700}
              fontSize="1rem"
              mb={0}
              data-test-id="routine-details"
              textTransform="uppercase"
              maxW={{ base: '80%', lg: 'full' }}
              whiteSpace="pre-line"
            >
              {event.routine.artist} - {event.routine.songName}
            </Text>
            <Text
              fontWeight={400}
              mb={0}
              data-test-id="event-date"
            >{`${startDate} @ ${startTime}`}</Text>
            <Text color="gray.700" fontWeight={400} data-test-id="event-location">
              {event.location.name}
            </Text>
          </Flex>
          <Spacer />
          <Flex direction="column" pb="0.25rem">
            <Text mt="0.75rem" mb={0} fontWeight={700} fontSize="1rem" data-test-id="price">
              £<span style={{ fontSize: '1.375rem' }}>{event.ticketPrice}</span>
            </Text>
            <Spacer />
            {isMobile && (
              <IconButton
                borderRadius="100%"
                icon={<FontAwesomeIcon icon={faAngleRight} />}
                aria-label={''}
              />
            )}
          </Flex>
        </Flex>
      </Button>
    </>
  );
}
