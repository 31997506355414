import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { ROUTINE_POSITION } from '../../constants/enums';
import {
  currentRoutinePreviewEvents,
  nextRoutinePreviewEvents,
  ROUTINE_TUTORIAL_SECTION_CURRENT_CLICKED,
  ROUTINE_TUTORIAL_SECTION_NEXT_CLICKED,
} from '../../constants/events';
import { useTypedSelector } from '../../hooks/store';
import { useGetRoutinesCurrentSetQuery } from '../../store/api';
import analyticsEvent from '../../utils/logEvent';
import { serializeIrlRoutineEventData } from '../../utils/serialize/analyticsEventsData';
import VideoPlayer from '../ui/VideoPlayer';
import CurrentRoutinesPreviewSectionContent from './CurrentRoutinesPreviewSectionContent';

const containerStyle = {
  position: 'relative',
  overflow: 'hidden',
} as const;

const flexContainerStyle = {
  flexDirection: { base: 'column-reverse', lg: 'row' },
} as const;

const videoContainerStyle = {
  width: { base: '100%', lg: '50%', xl: '60%' },
  position: 'relative',
  overflow: 'hidden',
  height: { base: '56.25vw', lg: '36.5rem', '2xl': '40rem' },
} as const;

const contentContainerStyle = {
  position: 'relative',
  marginLeft: -20,
  paddingLeft: 20,
  paddingRight: { base: 5, lg: 10 },
  paddingY: 8,
  cursor: 'pointer',
} as const;

const contentFlexStyle = {
  width: { base: '100%', lg: '50%', xl: '40%' },
  paddingRight: '0 !important',
  paddingBottom: '0 !important',
  paddingTop: { base: '0 !important', lg: '4rem !important', xl: '5rem !important' },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
} as const;

const desktopHeadingContainerStyle = {
  display: { base: 'none', lg: 'block' },
  marginBottom: { base: 8, lg: 4 },
  paddingRight: 8,
} as const;

export default function CurrentRoutinesPreviewSection() {
  const { routines } = useTypedSelector((state) => state);
  const { data, isFetching, isLoading, isError } = useGetRoutinesCurrentSetQuery(
    !!routines.irlCurrent && routines.irlNext ? skipToken : undefined,
  );
  const [selectedTab, setSelectedTab] = useState<ROUTINE_POSITION>(ROUTINE_POSITION.CURRENT);
  const [analyticsRoutineData, setAnalyticsRoutineData] = useState<any | null>(null);

  const t = useTranslations('QueenHub');

  const currentRoutine = routines.irlCurrent;
  const nextRoutine = routines.irlNext;

  useEffect(() => {
    setAnalyticsRoutineData({
      origin_component: 'current_routine_preview_section',
      ...(selectedTab === ROUTINE_POSITION.CURRENT &&
        currentRoutine &&
        serializeIrlRoutineEventData(currentRoutine)),

      ...(selectedTab === ROUTINE_POSITION.NEXT &&
        nextRoutine &&
        serializeIrlRoutineEventData(nextRoutine)),
    });
  }, [routines, selectedTab, currentRoutine, nextRoutine]);

  function onTabChange(index: number) {
    if (index === 0) {
      setSelectedTab(ROUTINE_POSITION.CURRENT);
      analyticsEvent(ROUTINE_TUTORIAL_SECTION_CURRENT_CLICKED, analyticsRoutineData);
    } else {
      setSelectedTab(ROUTINE_POSITION.NEXT);
      analyticsEvent(ROUTINE_TUTORIAL_SECTION_NEXT_CLICKED, analyticsRoutineData);
    }
  }

  function SectionHeading() {
    return (
      <>
        <Heading as="h1" size="h-xl">
          {t('heading')}
        </Heading>
        <Text fontSize="lg" fontWeight={450}>
          {t('description')}
        </Text>
      </>
    );
  }

  return (
    <Container sx={containerStyle} size="lg" background="gray.900">
      <Box display={{ lg: 'none' }} px={5} py={10}>
        <SectionHeading />
      </Box>
      <Flex sx={flexContainerStyle} data-test-id="current-routines-section-flex">
        <Container size="md" sx={contentFlexStyle}>
          <Box sx={desktopHeadingContainerStyle}>
            <SectionHeading />
          </Box>
          <Box>
            {currentRoutine && (
              <Box
                onClick={() => onTabChange(0)}
                sx={contentContainerStyle}
                background={selectedTab === ROUTINE_POSITION.CURRENT ? 'pink.500' : 'black'}
                data-test-id="current-routines-preview-current"
              >
                <CurrentRoutinesPreviewSectionContent
                  routine={currentRoutine}
                  position={ROUTINE_POSITION.CURRENT}
                />
              </Box>
            )}
            {nextRoutine && (
              <Box
                onClick={() => onTabChange(1)}
                sx={contentContainerStyle}
                background={selectedTab === ROUTINE_POSITION.NEXT ? 'pink.500' : 'black'}
                data-test-id="current-routines-preview-next"
              >
                <CurrentRoutinesPreviewSectionContent
                  routine={nextRoutine}
                  position={ROUTINE_POSITION.NEXT}
                />
              </Box>
            )}
          </Box>
        </Container>
        <Box sx={videoContainerStyle} data-test-id="current-routines-preview-video">
          {/* Render all video components on initial load, but hide them when not selected.
          This prevents a lag when switching just the video url/reloading the video component */}
          {selectedTab === ROUTINE_POSITION.CURRENT && currentRoutine?.previewVideo && (
            <VideoPlayer
              url={currentRoutine.previewVideo}
              previewImage={currentRoutine?.previewImage}
              display={selectedTab === ROUTINE_POSITION.CURRENT}
              isBackground={true}
              isBackgroundPreview={true}
              muted={false}
              useCustomFullscreenControl={false}
              analyticsEvents={currentRoutinePreviewEvents}
              analyticsEventsData={analyticsRoutineData}
            />
          )}
          {selectedTab === ROUTINE_POSITION.NEXT && nextRoutine?.previewVideo && (
            <VideoPlayer
              url={nextRoutine.previewVideo}
              previewImage={nextRoutine?.previewImage}
              display={selectedTab === ROUTINE_POSITION.NEXT}
              isBackground={true}
              isBackgroundPreview={true}
              muted={false}
              useCustomFullscreenControl={false}
              analyticsEvents={nextRoutinePreviewEvents}
              analyticsEventsData={analyticsRoutineData}
            />
          )}
        </Box>
      </Flex>
    </Container>
  );
}
