import { Box, Button, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';
import { AUTH_MODAL_PAGES, ROUTINE_POSITION, SUBSCRIBE_MODAL_PAGES } from '../../constants/enums';
import { BACKSTAGE_URL } from '../../constants/variables';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { openAuthModal, openSubscribeModal } from '../../store/modalsSlice';
import RoutinePreviewCard from '../cards/RoutinePreviewCard';

export default function AccessVipPageSection() {
  const t = useTranslations('QueenHub.accessVipPageSection');
  const tGlobal = useTranslations('Global');
  const user = useTypedSelector((state) => state.user);
  const isVip = user.isVip;
  const dispatch: any = useAppDispatch();

  const titleFlexStyle = {
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    mb: 16,
  };

  const contentFlexStyle = {
    alignItems: 'center',
    flexDirection: { base: 'column-reverse', md: 'row' },
    gap: { base: 8, md: 12 },
  };

  function onClickVipVideos() {
    if (!!user.firebaseUid && !!user.id) {
      dispatch(openSubscribeModal(SUBSCRIBE_MODAL_PAGES.DETAILS));
    } else {
      dispatch(openAuthModal(AUTH_MODAL_PAGES.SIGN_UP));
    }
  }

  return (
    <Container size="lg" background="black">
      <Container size="md" pb={{ base: '0 !important', md: '2.5rem !important' }}>
        <Flex sx={titleFlexStyle}>
          <Heading as="h2" size="h-xl" color="pink.500" data-test-id="access-vip-title">
            {t('heading')}
          </Heading>
          <Text size="h-3xl">{t('description')}</Text>
        </Flex>

        <Flex sx={contentFlexStyle}>
          <Box flex={1}>
            <Heading as="h3" size="h-md" color="pink.500">
              {t('infoSection.heading')}
            </Heading>
            <Text size="h-2xl">{t('infoSection.subHeading')}</Text>
            <Text>
              {isVip ? t('infoSection.description.vip') : t('infoSection.description.nonVip')}
            </Text>
            {!isVip ? (
              <>
                <Text size="h-lg" maxWidth={{ base: 64, lg: 72, xl: 80 }}>
                  {tGlobal('subscribeCTA.firstMonth')}
                </Text>
                <Text fontSize="xl" textTransform="uppercase" color="pink.500">
                  {tGlobal('subscribeCTA.price')}
                </Text>
              </>
            ) : (
              <></>
            )}
            {isVip ? (
              <NextLink href={`${BACKSTAGE_URL}/vip-videos`} passHref>
                <Button mt={4} maxWidth={48} data-test-id="vip-button">
                  {t('infoSection.button.isVip')}
                </Button>
              </NextLink>
            ) : (
              <Button mt={2} maxWidth={48} onClick={onClickVipVideos} data-test-id="non-vip-button">
                {!!user.firebaseUid && !!user.id
                  ? t('infoSection.button.notVip')
                  : t('infoSection.button.notLoggedIn')}
              </Button>
            )}
          </Box>

          <Box flex={1} data-test-id="routine-preview-container">
            <RoutinePreviewCard position={ROUTINE_POSITION.CURRENT} />
          </Box>
        </Flex>
      </Container>
    </Container>
  );
}
