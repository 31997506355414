import { Place } from '../types/places';

// Gets lat value from Autopredict search result (PlaceResult type) as callable function, or from our custom serialized Place type
export function getPlaceLat(place: Place | google.maps.places.PlaceResult): number {
  if (typeof place.geometry?.location?.lat === 'function') {
    return place.geometry.location.lat();
  } else {
    return place.geometry?.location?.lat || -1;
  }
}
// Gets lng value from Autopredict search result (PlaceResult type) as callable function, or from our custom serialized Place type
export function getPlaceLng(place: Place | google.maps.places.PlaceResult): number {
  if (typeof place.geometry?.location?.lng === 'function') {
    return place.geometry.location.lng();
  } else {
    return place.geometry?.location?.lng || -1;
  }
}
