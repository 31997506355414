import {
  getStoryblokApi,
  StoryblokComponent,
  StoryData,
  StoryParams,
  useStoryblokState,
} from '@storyblok/react';
import type { GetStaticPropsContext } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import FindClass from '../../components/classes/FindClass';
import AccessVipPageSection from '../../components/marketing/AccessVipPageSection';
import CurrentRoutinesPreviewSection from '../../components/routines/CurrentRoutinesPreviewSection';
import { EVENT_BOOK_PAGES } from '../../constants/enums';
import { useAppDispatch } from '../../hooks/store';
import { openEventBookModal } from '../../store/modalsSlice';

interface Props {
  story: StoryData;
  preview: boolean;
  storyblokParams: StoryParams;
  locale: string;
}

export default function QueenHub(props: Props) {
  const { story, preview } = props;
  const editableStory: StoryData = useStoryblokState(story, {}, preview);

  const router = useRouter();
  const dispatch: any = useAppDispatch();

  useEffect(() => {
    const { pathname, query } = router;
    const { event_checkout_success: openEventBookSuccess } = router.query;

    if (openEventBookSuccess) {
      // analyticsEvent(SUBSCRIBE_CHECKOUT_REDIRECT_COMPLETE);
      dispatch(openEventBookModal(EVENT_BOOK_PAGES.SUCCESS));
      delete router.query.event_checkout_success;
      router.replace({ pathname, query }, undefined, { shallow: true });
    }
  }, [dispatch, router, router.query]);

  return (
    <>
      <CurrentRoutinesPreviewSection />
      <FindClass />
      <AccessVipPageSection />
      <StoryblokComponent blok={editableStory.content} />
    </>
  );
}

export async function getStaticProps({ preview = false, locale }: GetStaticPropsContext) {
  const storyblokSlug = 'backstage/queen-hub';

  const storyblokParams = {
    version: preview ? 'draft' : 'published',
    language: locale,
  };

  const storyblokApi = getStoryblokApi();
  const { data } = await storyblokApi.get(`cdn/stories/${storyblokSlug}`, storyblokParams);

  return {
    props: {
      story: data ? data.story : false,
      key: data ? data.story.id : false,
      preview: preview,
      locale: locale,
      messages: {
        ...require(`../../messages/queenHub/${locale}.json`),
        ...require(`../../messages/bossHub/${locale}.json`),
        ...require(`../../messages/global/${locale}.json`),
      },
    },
    revalidate: 3600, // revalidate every hour
  };
}
