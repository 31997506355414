import { Box, Heading, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { ROUTINE_POSITION } from '../../constants/enums';
import { IrlRoutine } from '../../types/routines';
import { formatRoutineDateRange } from '../../utils/dateTime';

interface Props {
  routine: IrlRoutine;
  position: ROUTINE_POSITION;
}

export default function CurrentRoutinesPreviewSectionContent(props: Props) {
  const { routine, position } = props;
  const t = useTranslations('Global');

  return (
    <Box>
      <Heading as="h2" size="h-sm" mb={1} right={10} display="inline-block" mr={2}>
        {t(position === ROUTINE_POSITION.CURRENT ? 'currentLabel' : 'nextLabel')}
      </Heading>
      <Text mb={1} display="inline-block">
        {formatRoutineDateRange(routine.startDate, routine.endDate)}
      </Text>
      <Text size="h-md" mb={0}>
        {routine.artist}
      </Text>
      <Text size={routine.songName.length > 30 ? 'h-lg' : 'h-xl'} mb={0}>
        {routine.songName}
      </Text>
      <Text>
        {t('choreographedBy')} <strong>{routine.choreographer.name}</strong>
      </Text>
    </Box>
  );
}
